<template>
  <div class="navbar bg-base-100 shadow-sm shadow-slate-400/50 py-0">
    <div class="container mx-auto">
      <div class="flex-1 items-center">
        <NuxtLink :to="isAuthenticated?'/dashboard':'/'" class="btn btn-ghost">
          <img src="@/assets/img/Pi_logo.png"   
          width="78px"
          height="78px" 
          alt="Background Image">
        </NuxtLink>
        <!-- <a class="btn btn-ghost text-xl">HR assist</a> -->
      </div>
      <div class="flex-none">
        <ul class="menu menu-horizontal px-2 py-1 items-center">
          <li class="px-2">
            <NuxtLink to="/jobs">
              <div class="text-center">
                <Icon name="uil:suitcase" size="20" /><br />
                Jobs
              </div>
            </NuxtLink>
          </li>
          <li class="px-2">
            <NuxtLink to="/candidates">
              <div class="text-center">
                <Icon name="uil:user" size="20" /><br> Candidates
              </div>
            </NuxtLink>
          </li>
          <li class="px-2">
            <NuxtLink to="/file-status">
              <div class="text-center">
                <Icon name="uil:truck-loading" size="20" /><br> Upload status
              </div>
            </NuxtLink>
          </li>
          <li>
          </li>
          <li>
            <BaseAvatar />
          </li>
        </ul>

      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useMyUserStore } from '~/store/user';
const rResumeModal = ref<HTMLDialogElement | null>(null);
const rJobDescriptionModal = ref<HTMLDialogElement | null>(null);
const rBulkUploadModal = ref<HTMLDialogElement | null>(null);
//setting default preference

const userStore = useMyUserStore();

const handleResumeModal = () => {
  rResumeModal.value?.showModal();
};
const handleJobDescriptionModal = () => {
  rJobDescriptionModal.value?.showModal();
};
const handleBulkUploadModal = () => {
  rBulkUploadModal.value?.showModal();
};

const isAuthenticated = computed<any>(() => userStore.authenticated);

</script>

<style>
.router-link-active.router-link-exact-active {
  color: oklch(var(--p));
}

.router-link-active.router-link-exact-active:after {
  content: '';
  width: 100%;
  height: 5px;
  background-color: oklch(var(--p));
  border-radius: 2px;
  position: absolute;
  bottom: -5px;
  left: 0;
}
</style>
