<template>
  <div>
    <BaseHeader />
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { useMyUserStore } from '~/store/user';
import { useMyAppStore } from '~/store/app';
import { useMyJobStore } from '~/store/job';

const userStore = useMyUserStore();
import { fetchUserAttributes } from 'aws-amplify/auth';
const appStore = useMyAppStore();
onBeforeMount(async () => {
  appStore.incrementLoading();
  try {
    const userAttributes = await fetchUserAttributes();
    if (!userAttributes) {
      throw new Error('No user attributes found')
    }
    userStore.isAuthenticated = true
    userStore.setUser({
      email: userAttributes.email || '',
      name: userAttributes.name || '',
      picture: userAttributes.picture || '',
      sub: userAttributes.sub || '',
    })
    appStore.decrementLoading();
  } catch (error) {
    appStore.decrementLoading();
    userStore.isAuthenticated = false
    appStore.error({
      text: 'User not authenticated, Please login to continue.',
    })
    navigateTo('/')
    throw createError({
      message: 'User not authenticated',
      statusCode: 401,
    })
  }
});
const jobStore = useMyJobStore();

import { useMyCandidatesStore } from "~/store/candidate";
const candidateStore = useMyCandidatesStore();

onMounted(() => {
  appStore.incrementLoading();
  Promise.all([jobStore.getAllJobDescriptions(), candidateStore.getAllCandidates()])
    .then(() => {
      appStore.decrementLoading();
    })
    .catch(() => {
      appStore.decrementLoading();
      throw createError({ statusCode: 404, statusMessage: 'Page Not Found' })
    });
});
</script>

<style></style>