<template>
  <Menu as="div" class="relative inline-block text-left p-0">
    <div>
      <MenuButton class="btn btn-circle">
        <div class="avatar">
          <div class="rounded-full">
            <img :src="userStore.user.picture" />
          </div>
        </div>
      </MenuButton>
    </div>
    <transition enter-active-class="transition duration-100 ease-out" enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100" leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100" leave-to-class="transform scale-95 opacity-0">
      <MenuItems
        class="absolute right-0 mt-2 w-56 z-50  rounded-md bg-base-100 shadow-lg ring-1 ring-base-content/5 focus:outline-none">
        <div class="pt-4 px-6">
          <p class="text-sm opacity-50">Account</p>
          <div class="flex items-center gap-2 py-2">
            <div class="w-1/3 avatar">
              <div class="rounded-full">
                <img :src="userStore.user.picture" />
              </div>
            </div>
            <div class="w-2/3">
              <p class="text-md font-bold">{{ userStore.user.name }}</p>
              <p class="text-xs text-ellipsis overflow-hidden">
                {{ userStore.user.email }} </p>
            </div>
          </div>
        </div>
        <div class="bg-base-content/10 h-px w-full mt-2"></div>
        <MenuItem v-slot="{ active }">
        <button :class="[
          active ? 'bg-base-200' : 'text-base-content',
          'group flex w-full items-center rounded-md px-4 pb-2 pt-2 text-sm',
        ]" @click="toggleTheme">
          <!-- sun icon -->
          <Icon name="uil:sun" size="25" v-if="colorMode.preference === 'dark'" class=" fill-current" />
          <!-- moon icon -->
          <Icon name="uil:moon" size="25" class="fill-current" v-if="colorMode.preference === 'light'" />
          <span class="ml-2">Change Theme</span>
        </button>
        </MenuItem>
        <MenuItem v-slot="{ active }">
        <button @click="userStore.signOut()" :class="[
          active ? 'bg-base-200' : 'text-base-content',
          'group flex w-full items-center rounded-md px-4 py-2 text-sm',
        ]">
          <Icon name="uil:sign-out-alt" size="25" class="fill-current" />
          <span class="ml-2">Logout</span>
        </button>
        </MenuItem>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script lang="ts" setup>
const colorMode = useColorMode();
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { useMyUserStore } from '~/store/user';
const userStore = useMyUserStore();
// colorMode.preference === "system" ? "system" : "light";
// setting the theme to dark by default
colorMode.preference = "dark"
const toggleTheme = () => {
  colorMode.preference = colorMode.value === "light" ? "dark" : "light";
};
</script>

<style></style>